// import React from "react";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import HostRoom from "./components/HostRoom";
// import UserRoom from "./components/UserRoom";

// const App = () => {
//   return (
//     <div>
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<HostRoom />} />
//           <Route path="/join/:roomId" element={<UserRoom />} />
//         </Routes>
//       </BrowserRouter>
//     </div>
//   );
// };

// export default App;

import React, { useState, useEffect, useRef, useCallback } from "react";
import io from "socket.io-client";

const socket = io("https://api.nyaynet.online");

function Video({ stream, muted }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  return <video ref={videoRef} autoPlay playsInline muted={muted} />;
}

function App() {
  const [roomName, setRoomName] = useState("");
  const [username, setUsername] = useState("");
  const [isHost, setIsHost] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [joinRequests, setJoinRequests] = useState([]);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStreams, setRemoteStreams] = useState({});
  const [isJoined, setIsJoined] = useState(false);
  const peerConnections = useRef({});

  const createPeerConnection = useCallback(
    (userId) => {
      if (!localStream) {
        console.error("Local stream not available");
        return;
      }

      const pc = new RTCPeerConnection({
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
      });

      pc.onicecandidate = (event) => {
        if (event.candidate) {
          socket.emit("ice-candidate", { userId, candidate: event.candidate });
        }
      };

      pc.ontrack = (event) => {
        setRemoteStreams((prev) => ({
          ...prev,
          [userId]: event.streams[0],
        }));
      };

      localStream
        .getTracks()
        .forEach((track) => pc.addTrack(track, localStream));

      peerConnections.current[userId] = pc;

      pc.createOffer()
        .then((offer) => pc.setLocalDescription(offer))
        .then(() => {
          socket.emit("offer", { userId, offer: pc.localDescription });
        })
        .catch((e) => console.error("Error creating offer", e));
    },
    [localStream]
  );

  useEffect(() => {
    socket.on("user-joined", ({ userId, username }) => {
      setParticipants((prev) => [
        ...prev,
        { id: userId, username, muted: false, videoEnabled: true },
      ]);
      if (localStream) {
        createPeerConnection(userId);
      }
    });

    socket.on("audio-toggled", ({ userId, muted }) => {
      setParticipants((prev) =>
        prev.map((p) => (p.id === userId ? { ...p, muted } : p))
      );
    });

    socket.on("video-toggled", ({ userId, videoEnabled }) => {
      setParticipants((prev) =>
        prev.map((p) => (p.id === userId ? { ...p, videoEnabled } : p))
      );
    });

    socket.on("join-request", ({ userId }) => {
      setJoinRequests((prev) => [...prev, userId]);
    });

    socket.on("user-admitted", ({ userId }) => {
      setJoinRequests((prev) => prev.filter((id) => id !== userId));
    });

    socket.on("ice-candidate", ({ userId, candidate }) => {
      peerConnections.current[userId]
        ?.addIceCandidate(new RTCIceCandidate(candidate))
        .catch((e) => console.error("Error adding received ice candidate", e));
    });

    socket.on("offer", async ({ userId, offer }) => {
      if (!peerConnections.current[userId]) {
        createPeerConnection(userId);
      }
      const pc = peerConnections.current[userId];
      await pc.setRemoteDescription(new RTCSessionDescription(offer));
      const answer = await pc.createAnswer();
      await pc.setLocalDescription(answer);
      socket.emit("answer", { userId, answer });
    });

    socket.on("answer", ({ userId, answer }) => {
      peerConnections.current[userId]
        ?.setRemoteDescription(new RTCSessionDescription(answer))
        .catch((e) => console.error("Error setting remote description", e));
    });

    return () => {
      socket.off("user-joined");
      socket.off("audio-toggled");
      socket.off("video-toggled");
      socket.off("join-request");
      socket.off("user-admitted");
      socket.off("ice-candidate");
      socket.off("offer");
      socket.off("answer");

      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
      }

      Object.values(peerConnections.current).forEach((pc) => pc.close());
    };
  }, [localStream, createPeerConnection]);

  const joinRoom = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      setLocalStream(stream);
      socket.emit("join-room", { roomName, username, isHost });
      setIsJoined(true);
      setParticipants([
        { id: socket.id, username, muted: false, videoEnabled: true },
      ]);
    } catch (error) {
      console.error("Error accessing media devices:", error);
      alert(
        "Failed to access camera and microphone. Please check your permissions."
      );
    }
  };

  const toggleAudio = (userId) => {
    const participant = participants.find((p) => p.id === userId);
    if (!participant) return;

    const newMutedState = !participant.muted;
    if (userId === socket.id && localStream) {
      localStream.getAudioTracks()[0].enabled = !newMutedState;
    }
    setParticipants((prev) =>
      prev.map((p) => (p.id === userId ? { ...p, muted: newMutedState } : p))
    );
    socket.emit("toggle-audio", { roomName, userId, muted: newMutedState });
  };

  const toggleVideo = (userId) => {
    const participant = participants.find((p) => p.id === userId);
    if (!participant) return;

    const newVideoState = !participant.videoEnabled;
    if (userId === socket.id && localStream) {
      localStream.getVideoTracks()[0].enabled = newVideoState;
    }
    setParticipants((prev) =>
      prev.map((p) =>
        p.id === userId ? { ...p, videoEnabled: newVideoState } : p
      )
    );
    socket.emit("toggle-video", {
      roomName,
      userId,
      videoEnabled: newVideoState,
    });
  };

  const admitUser = (userId) => {
    socket.emit("admit-user", { roomName, userId });
  };

  return (
    <div>
      <h1>Video Conference</h1>
      {!isJoined ? (
        <div>
          <input
            type="text"
            placeholder="Room Name"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <label>
            <input
              type="checkbox"
              checked={isHost}
              onChange={(e) => setIsHost(e.target.checked)}
            />
            Join as Host
          </label>
          <button onClick={joinRoom}>Join Room</button>
        </div>
      ) : (
        <div>
          <div>
            <h2>Local Video</h2>
            <Video stream={localStream} muted={true} />
            <button onClick={() => toggleAudio(socket.id)}>
              {participants.find((p) => p.id === socket.id)?.muted
                ? "Unmute"
                : "Mute"}
            </button>
            <button onClick={() => toggleVideo(socket.id)}>
              {participants.find((p) => p.id === socket.id)?.videoEnabled
                ? "Disable Video"
                : "Enable Video"}
            </button>
          </div>

          <div>
            <h2>Participants</h2>
            {participants.map((participant) => (
              <div key={participant.id}>
                <span>{participant.username}</span>
                {participant.id !== socket.id && (
                  <Video stream={remoteStreams[participant.id]} muted={false} />
                )}
                {(isHost || participant.id === socket.id) && (
                  <>
                    <button onClick={() => toggleAudio(participant.id)}>
                      {participant.muted ? "Unmute" : "Mute"}
                    </button>
                    <button onClick={() => toggleVideo(participant.id)}>
                      {participant.videoEnabled
                        ? "Disable Video"
                        : "Enable Video"}
                    </button>
                  </>
                )}
              </div>
            ))}
          </div>

          {isHost && (
            <div>
              <h2>Join Requests</h2>
              {joinRequests.map((userId) => (
                <div key={userId}>
                  <span>User {userId} wants to join</span>
                  <button onClick={() => admitUser(userId)}>Admit</button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
